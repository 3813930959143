<template>
  <div>

      <!-------- 弹出框表单 start ---------->
      <el-dialog
              v-model="formData.show"
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              :show-close="false"
              :modal="false"
              center
              width="90%"
              title="用户登录">

        <template v-slot:title>
          <div>
            <img width="90" src="@/assets/login-logo.png" alt="">
          </div>
          <div style="font-size:20px;font-weight: bold;margin: 5px 0;">
            红了樱桃管理系统
          </div>
        </template>

          <el-form
                  ref="loginRef"
                  :rules="formData.rules"
                  :model="formData.data"
                  max-width="80%"
                  style="max-width: 90%;margin:0 auto;"
          >
              <el-form-item prop="mobile">
                  <el-input
                          v-model="formData.data.mobile"
                          type="number"
                          placeholder="请输入登陆手机号"
                          size="large"
                  >
                      <template #prepend>账号</template>
                  </el-input>
              </el-form-item>
              <el-form-item prop="password">
                  <el-input
                          v-model="formData.data.password"
                          type="password"
                          placeholder="请输入登陆密码"
                          show-password
                          size="large"
                  >
                  <template #prepend>密码</template>
                  </el-input>
              </el-form-item>
              <el-form-item>
                  <el-button type="danger" size="large" style="margin:0 auto;" @click="onLogin">
                      &nbsp;&nbsp;&nbsp;&nbsp;登陆&nbsp;&nbsp;&nbsp;&nbsp;
                  </el-button>
              </el-form-item>
          </el-form>

        <el-divider>
          OA登录
        </el-divider>

        <el-row>
          <el-col :span="24" style="text-align: center">
            <router-link :to="{name: 'socialite.login', params: {platform: 'wework'}}">
              <img src="@/assets/socialite/wework.png" alt="企业微信">
            </router-link>
          </el-col>
        </el-row>

      </el-dialog>
      <!-------- 弹出框表单 end ---------->

  </div>
</template>


<script>
  import {apiLogin} from '@/api/authApi'
  import {loginUser} from '@/plugins/auth'
  export default {
      name: 'login',
      data () {
          return {
              // 创建表单
              formData:{
                  data: {
                      mobile: '',
                      password: ''
                  },
                  rules: {
                      mobile: [{
                          required: true,
                          message: '请输入手机号',
                          trigger: 'blur',
                      },],
                      password: [{
                          required: true,
                          message: '请输入密码',
                          trigger: 'blur',
                      }],
                  },
                  show: true,
              },
          }
      },
      methods: {
          onLogin () {
              this.$refs['loginRef'].validate(valid => {
                  if (valid) {
                      apiLogin(this.formData.data).then(data => {
                          loginUser(data)

                          // 去重定向地址或主页
                          if (this.$route.query.redirect_url) {
                              window.location = this.$route.query.redirect_url
                          } else {
                              this.$router.push({name: 'index'})
                          }
                      })
                  } else {
                      return false
                  }
              })
          },
      }
  }
</script>
